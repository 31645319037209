<template>
	<el-dialog title="运单锁定" top="1vh" width="600px" :visible.sync="is_show_in_page">
		<el-form  label-width="100px">
			
			<el-form-item class="el_form_item" label="锁定原因">
				<el-input class="el_inner_width" v-model="lock_reason" placeholder="请输入锁定原因" clearable></el-input>
			</el-form-item>
			<div style="margin:30px 0 0 0;text-align:center">
				<el-button type="primary" @click="sub">提交</el-button>
			</div>
		</el-form>

	</el-dialog>
</template>
<script>
	export default {
		props:{
			is_show:[Number,Boolean],//是否显示
		},
		data() {
			return {
				is_show_in_page:false,
				lock_reason:'',
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.is_show_in_page=true;
				}else{
					this.is_show_in_page=false;
				}
			}
		},
		methods: {

			//提交
			sub(){

				//锁定原因
				if(!this.lock_reason.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少锁定原因'
					});
					return;
				}

				//通知
				this.$emit('close',{
					lock_reason:this.lock_reason.trim()
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
	}

	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}

	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	
	.el_inner_width{
		width:94%;
	}
	
</style>