<template>
	<el-dialog title="运单/流水上报列表" top="1vh" width="60%" :visible.sync="is_show_in_Page">

		<!-- 操作行 -->
		<div style="margin-top:-20px">
			<el-button v-if="tord_info.upl_status==1" @click="tord_upl_view_open" type="primary" size="small">上报运单</el-button>
			<el-button v-if="tord_info.upl_status==2" @click="outcash_upl" type="success" size="small">上报流水</el-button>
		</div>
		
		<!-- 已上报列表 -->
		<div style="margin-top:15px;height:200px">
			<el-table :data="upl_list" :border="true" :stripe="true" size="small" height="100%" v-loading="loading">
				<el-table-column label="上报目标" prop="aim_text"></el-table-column>
				<el-table-column label="上报时间" prop="creat_time_text" width="190"></el-table-column>
				<el-table-column label="操作人">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.admin_user_info.name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="类型" prop="type_text"></el-table-column>
				<el-table-column label="状态" prop="status_text"></el-table-column>
			</el-table>
		</div>

		<!-- 上报运单选择上报节点 -->
		<el-dialog top="2vh" width="400px" title="选择运单上报节点" append-to-body custom-class="el-dialog-s" :visible.sync="upl_aim_choose.is_show">
			
			<el-form  label-width="80px">
				
				<el-form-item class="el_form_item" label="上报节点">
					<el-select class="el_inner_width" v-model="upl_aim_choose.rel" placeholder="请选择上报节点">
						<el-option v-for="(cname,aim) in upl_aim_list" :key="aim" :label="cname" :value="aim"></el-option>
					</el-select>
				</el-form-item>
				<div style="margin:30px 0 0 0;text-align:center">
					<el-button type="primary" @click="tord_upl_sub" size="small">提交</el-button>
				</div>
			</el-form>

		</el-dialog>

	</el-dialog>
</template>
<script>
	export default {
		props:{
			tord_info:Object,//运单详情
			tord_from:String,//运单来源
			is_show:Number,//是否显示
		},
		data() {
			return {

				//列表是否显示
				is_show_in_Page:false,

				//上报列表
				upl_list:[],

				//加载状态
				loading: false,//loading特效

				//上报目标
				upl_aim_list:{},

				//选择运单上报节点选择界面
				upl_aim_choose:{
					is_show:false,
					rel:'',
				}
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.is_show_in_Page=true;
					this.init();
				}
			},
		},
		methods: {

			//初始化
			init(){

				//如果没有司机编号则不读取数据
				if(!this.tord_info){
					return;
				}

				//加载轨迹校验组件
				let path_check=require('@/my/path_check.js').default
				path_check.init(this);
				this.path_check=path_check;

				//列表页初始化
				this.upl_list=[];

				//读取数据
				this.load_data()
			},

			//运单出金流水上报
			outcash_upl(item){

				//询问
				this.$my.other.confirm({
					content:`点击'确定'上报流水`,
					confirm:()=>{
						
						//调起上报函数
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_outcash_upl_by_admin',
								truck_tord_num:this.tord_info.truck_tord_num,
								tord_from:this.tord_from,
								upl_aim:this.upl_list[0].aim
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'上报成功'
								});
								
								//刷新
								this.load_data();
							},
						});
					}
				});
			},

			//打开运单上报节点选择界面
			tord_upl_view_open(item){
				this.upl_aim_choose.rel='';
				this.upl_aim_choose.is_show=true;
			},
			tord_upl_sub(){

				if(!this.upl_aim_choose.rel){
					this.$my.other.msg({
						type:'warning',
						str:'请选择上报节点'
					});
					return;
				}

				//获取运单轨迹
				this.$my.net.req({
					data:{
						mod:'truck',
						ctr:'truck_trajectory',
						truck_plate_num:this.tord_info.truck_plate_num,
						start_time:this.tord_info.start_time,
						end_time:this.tord_info.arrived_time,
					},
					callback:(data)=>{

						if(data.list.length==0){
							this.$my.other.msg({
								type:'warning',
								str:'未读取到轨迹'
							});
							return;
						}

						//轨迹校验
						this.path_check.check({
							case_code:this.tord_info.case_code,
							aim_code:this.tord_info.aim_code,
							start_time:this.tord_info.start_time,
							arrived_time:this.tord_info.arrived_time,
							path:data.list,
							callback:(res)=>{

								console.log(res)

								//轨迹校验成功,直接上报
								if(res.code==0){
									
									//调起上报函数
									this.$my.net.req({
										data:{
											mod:'truck_tord_real_time',
											ctr:'tord_upl_by_admin',
											truck_tord_num:this.tord_info.truck_tord_num,
											tord_from:this.tord_from,
											aim:this.upl_aim_choose.rel,
											trajectory_status:2,
										},
										callback:(data)=>{
											this.$my.other.msg({
												type:'success',
												str:'上报成功'
											});
											this.upl_aim_choose.is_show=false
											
											//刷新
											this.load_data();
										},
									});
									
								}else if(res.code==1){

									//询问
									this.$my.other.confirm({
										content:`轨迹校验不合规,原因:'${res.error_info}',请酌情上报`,
										confirm:()=>{
											
											//调起上报函数
											this.$my.net.req({
												data:{
													mod:'truck_tord_real_time',
													ctr:'tord_upl_by_admin',
													truck_tord_num:this.tord_info.truck_tord_num,
													tord_from:this.tord_from,
													aim:this.upl_aim_choose.rel,
													trajectory_status:3,
												},
												callback:(data)=>{
													this.$my.other.msg({
														type:'success',
														str:'上报成功'
													});
													this.upl_aim_choose.is_show=false
													
													//刷新
													this.load_data();
												},
											});
										}
									});
								}
							}
						});
					}
				});
			},

			//读取数据
			load_data(){

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_upl_list_by_admin',
						truck_tord_num:this.tord_info.truck_tord_num
					},
					callback:(data)=>{

						//加载完毕
						this.loading=false

						//预处理
						for(let upl_item of data.list){

							//上报时间
							upl_item.creat_time_text=this.$my.other.totime(upl_item.creat_time);

							//上报目标
							upl_item.aim_text=data.upl_aim_list[upl_item.aim]

							//类型(1:运单上报,2:流水上报)
							switch(upl_item.type){
								
								//上报中
								case '1':upl_item.type_text='运单上报';break;

								//上报成功
								case '2':upl_item.type_text='流水上报';break;
							}

							//状态
							switch(upl_item.status){
								
								//上报中
								case '1':upl_item.status_text='上报中';break;

								//上报成功
								case '2':upl_item.status_text='上报成功';break;

								//上报失败
								case '3':upl_item.status_text='上报失败';break;
							}
						}
						
						//渲染
						this.upl_list=data.list
						this.upl_aim_list=data.upl_aim_list
					}
				});
			},
		}
	}
</script>

<style scoped>
	.el-dialog-s{
		z-index: 11;
	}
</style>