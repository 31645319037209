<template>
	<el-dialog top="1vh" title="待结算列表" width="90%" :visible.sync="this_is_show">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="90px">
			
			<el-form-item label-width="0">
				<el-button type="primary" @click="settle_open">添加结算</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list" :border="true" :stripe="true" size="small" height="600px" v-loading="list.loading">
				<el-table-column label="支付编号/运单编号" width="310px">
					<template slot-scope="scope">
						{{scope.row.payed_num}}/{{scope.row.creat_time_text}}
					</template>
				</el-table-column>
				<el-table-column label="支付信息" width="300px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.this_pay_type_text}}/{{scope.row.this_payed}}元/{{scope.row.pay_status_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="开票信息" width="300px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.this_invoiced}}元/{{scope.row.invoice_status_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="车队长结算">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.this_settled_of_truck_owner}}元/{{scope.row.settle_status_of_truck_owner_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="司机结算">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.this_settled_of_driver}}元/{{scope.row.settle_status_of_driver_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="备注信息">
					<template slot-scope="scope">
						<div class="tab_line_item">支付备注: {{scope.row.pay_mark?scope.row.pay_mark:'无'}}</div>
						<div class="tab_line_item">结算备注: {{scope.row.settle_mark?scope.row.settle_mark:'无'}}</div>
					</template>
				</el-table-column>

				<!-- 操作行 -->
				<el-table-column label="操作" width="48">
					<template slot-scope="scope">
						<el-button @click="del(scope.row)" size="mini" type="text" style="color:red">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 结算界面 -->
		<creat 
			@settle_end="settle_close"
			:tord_info="tord_info" 
			:is_show="creat.is_show" 
		></creat>
	</el-dialog>
</template>

<script>
	import creat from './creat.vue'
	export default {
		components:{
			creat,
		},
		props:{
			tord_info:Object,
			is_show:Number
		},
		data() {
			return {

				//页面是否显示
				this_is_show:false,

				//表格数据
				list: [],
				
				//添加结算
				creat:{
					is_show:0,
				},
			}
		},
		watch:{
			is_show(new_data){
				if(new_data==0){
					this.this_is_show=false
				}else {
					this.this_is_show=true
					this.get_page_data();
				}
			},
		},
		methods: {

			//打开结算界面
			settle_open(){

				//打开
				this.creat.is_show++
			},
			settle_close(){//关闭结算界面

				//关闭
				this.creat.is_show=0

				//刷新
				this.get_page_data();
			},

			//删除结算记录
			del(item){

				//支付状态
				if(item.pay_status==3){
					this.$my.other.msg({
						str:"'支付成功'时候不可以删除",
						type:'warning'
					});
					return;
				}

				//开票状态
				if(['1','4'].indexOf(item.invoice_status)==-1){
					this.$my.other.msg({
						str:"'开票中'或者'开票成功'时候不可以删除",
						type:'warning'
					});
					return;
				}

				//结算状态
				if(item.settle_status==3){
					this.$my.other.msg({
						str:"'结算成功'时候不可以删除",
						type:'warning'
					});
					return;
				}

				//询问
				this.$my.other.confirm({
					content:"点击确定删除此记录",
					confirm:()=>{
						
						//调用接口
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_settle_del_by_admin',
								payed_num:item.payed_num,
								truck_tord_num:item.truck_tord_num,
							},
							callback:(data)=>{

								//提醒
								this.$my.other.msg({
									str:'操作成功',
									type:'success'
								});
								
								//刷新本页
								this.get_page_data()
							}
						})
					}
				})
			},
			
			//驳回结算
			settle_refuse(item){

				//询问
				this.$my.other.confirm({
					content:"点击确定驳回打款申请",
					confirm:()=>{
						
						//调用接口
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_settle_refuse_by_admin',
								truck_tord_num:item.truck_tord_num,
								ra_num:item.ra_num,
							},
							callback:(data)=>{

								//提醒
								this.$my.other.msg({
									str:'操作成功',
									type:'success'
								});
								
								//刷新本页
								this.get_page_data()
							}
						})
					}
				})
			},

			//撤回结算
			settle_revoke(item){

				//询问
				this.$my.other.confirm({
					content:"点击确定撤销打款申请",
					confirm:()=>{
						
						//调用接口
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_settle_revoke_by_admin',
								truck_tord_num:item.truck_tord_num,
								ra_num:item.ra_num,
							},
							callback:(data)=>{

								//提醒
								this.$my.other.msg({
									str:'操作成功',
									type:'success'
								});
								
								//刷新本页
								this.get_page_data()
							}
						})
					}
				})
			},
			
			//获取列表数据
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_settle_list_by_admin',
						truck_tord_num:this.tord_info.truck_tord_num,
						num:100000,
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//成交时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);
							
							//本次支付类型(1:现金,2:燃油费,3:过路费)
							switch(item.this_pay_type){
								case '1':item.this_pay_type_text="现金";break;
								case '2':item.this_pay_type_text="燃油费";break;
								case '3':item.this_pay_type_text="过路费";break;
							}
							
							//支付状态(1:待审核,2:支付完成,3:支付失败/打回)
							switch(item.pay_status){
								case '1':item.pay_status_text="未支付";break;
								case '2':item.pay_status_text="待审核";break;
								case '3':
									item.pay_status_text=`支付完成 ${this.$my.other.totime(item.payed_time)}`;
									break;
								case '4':
									item.pay_status_text=`支付失败 ${this.$my.other.totime(item.payed_time)}`;
									break;
							}

							//开票状态(1:未申请,2:申请中,3:已开出,4:已驳回)
							switch(item.invoice_status){
								case '1':item.invoice_status_text="未申请";break;
								case '2':item.invoice_status_text="申请中";break;
								case '3':
									item.invoice_status_text=`已开出 ${this.$my.other.totime(item.invoiced_time)}`;
									break;
								case '4':
									item.invoice_status_text=`已驳回 ${this.$my.other.totime(item.invoiced_time)}`;
									break;
							}

							//结算给车队长的状态(1:未申请,2:待打款,3:已打款,4:结算失败)
							switch(item.settle_status_of_truck_owner){
								case '1':item.settle_status_of_truck_owner_text="未申请";break;
								case '2':item.settle_status_of_truck_owner_text="待打款";break;
								case '3':
									item.settle_status_of_truck_owner_text=`打款成功 ${this.$my.other.totime(item.settled_time_of_truck_owner)}`;
									break;
								case '4':
									item.settle_status_of_truck_owner_text=`打款失败 ${this.$my.other.totime(item.settled_time_of_truck_owner)}`;
									break;
							}

							//结算给司机的状态(1:未申请,2:待打款,3:已打款,4:结算失败)
							switch(item.settle_status_of_driver){
								case '1':item.settle_status_of_driver_text="未申请";break;
								case '2':item.settle_status_of_driver_text="待打款";break;
								case '3':
									item.settle_status_of_driver_text=`打款成功 ${this.$my.other.totime(item.settled_time_of_driver)}`;
									break;
								case '4':
									item.settle_status_of_driver_text=`打款失败 ${this.$my.other.totime(item.settled_time_of_driver)}`;
									break;
							}
						}

						//渲染
						this.list=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 134px);
	}
	.el_form_item{
		width: 275px;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>