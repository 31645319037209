<template>
	<el-dialog top="1vh" title="运费核定" width="1050px" :visible.sync="is_show_in_page" @close="close">
		<el-form class="form_ordinary" label-width="90px">


			<div class="big_tit" style="margin:0">货物信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货物名称" style="width:388px">
					<el-input class="el_inner_width" v-model="form.cargo_name_type" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="上游单号" style="width:588px">
					<el-input class="el_inner_width" v-model="form.out_trade_num" disabled></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">装货地信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="装货地" style="width:947px">
					<el-input v-model="form.case_addr_text" disabled></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="卸货地" style="width:947px">
					<el-input v-model="form.aim_addr_text" disabled></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">承运人/司机/货车信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="以上数据" style="width:947px">
					<el-input v-model="form.truck_owner_driver_truck_info_text" disabled></el-input>
				</el-form-item>
			</div>

			<div class="big_tit">费用信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="开票金额" style="width:240px">
					<el-input class="el_inner_width" v-model="new_invoice_total1" disabled>
						<el-button slot="append">元</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="运费" style="width:240px">
					<el-input class="el_inner_width" v-model="form.cost_info.freight_total" disabled>
						<el-button slot="append">元</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="服务费" style="width:240px">
					<el-input class="el_inner_width" v-model="form.cost_info.service_charge" disabled>
						<el-button slot="append">元</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="保障服务费" style="width:240px">
					<el-input class="el_inner_width" v-model="form.cost_info.insure_total" disabled>
						<el-button slot="append">元</el-button>
					</el-input>
				</el-form-item>
			</div> 
			<!-- <div class="tab1_inner">

			</div> -->
			<!-- <div class="big_tit">原运费数据</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货主需付" style="width:240px">
					<el-input class="el_inner_width" v-model="form.cost_info.freight_total" disabled>
						<el-button slot="append">元</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="剩余需付" style="width:240px">
					<el-input class="el_inner_width" v-model="freight_total_surplus" disabled>
						<el-button slot="append">元</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="平台需结" style="width:240px">
					<el-input class="el_inner_width" v-model="form.cost_info.give_carrier_total" disabled>
						<el-button slot="append">元</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="剩余需结" style="width:240px">
					<el-input class="el_inner_width" v-model="give_carrier_total_surplus" disabled>
						<el-button slot="append">元</el-button>
					</el-input>
				</el-form-item>
			</div> -->


			<div class="big_tit">新运费数据/重量体积</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货物重量" style="width:320px">
					<el-input class="el_inner_width" v-model="form.cargo_weight" type="number" clearable>
						<el-button slot="append">吨</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="货物体积" style="width:320px">
					<el-input class="el_inner_width" v-model="form.cargo_volume" type="number" clearable>
						<el-button slot="append">方</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="运输距离" style="width:340px">
					<el-input class="el_inner_width" v-model="form.distance">
						<el-button slot="append">公里</el-button>
						<el-button slot="append" @click="distance_refresh">刷新</el-button>
					</el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="计算方式" style="width:320px">
					<el-select class="el_inner_width" v-model="form.cost_info.calc_mode" @change="calc_mode_change" :disabled="payed_list_total>0" clearable>
						<el-option label="服务费率模式" value="1"></el-option>
						<el-option label="固定金额模式" value="2"></el-option>
					</el-select>
				</el-form-item>
				<!-- <el-form-item class="el_form_item" label="平台需结" style="width:320px">
					<el-input class="el_inner_width" v-model="new_give_carrier_total" type="number" :disabled="form.cost_info.calc_mode==1" clearable>
						<el-button slot="append">元</el-button>
					</el-input>
				</el-form-item> -->
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货主需付" style="width:320px">
					<el-input class="el_inner_width" v-model="new_freight_total" type="number" @input="freight_total_input" clearable>
						<el-button slot="append">元</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="给司机" style="width:320px;">
					<el-input class="el_inner_width" v-model="form.give_driver_total" @input="give_driver_proportion(form.give_driver_total)">
						<el-button slot="append">元</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="给车队长"  style="width:320px">
					<el-input class="el_inner_width" v-model="form.give_truck_owner_total" @input="give_truck_owner_proportion(form.give_truck_owner_total)">
						<el-button slot="append">元</el-button>
					</el-input>
				</el-form-item>
			   <el-form-item class="el_form_item"  style="width:320px">
					<el-checkbox v-model="manual_ver_checked" @change="manual_ver_change">保费从司机运费扣</el-checkbox>
				</el-form-item>
             	
				<!-- <el-form-item class="el_form_item" label="服务费" style="width:320px">
					<el-input class="el_inner_width" v-model="new_give_carrier_total" type="number"  clearable>
						<el-button slot="append">元</el-button>
					</el-input>
				</el-form-item> -->
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="开票金额" style="width:283px">
					<el-input class="el_inner_width" v-model="new_invoice_total"  @focus="focus_new_invoice_total(new_invoice_total)" type="number" clearable>
						<el-button slot="append">元</el-button>
					</el-input>
				</el-form-item>
				<el-button style="margin-top: 6px;margin-left: 1px;" @click="invoice_count" size="mini" type="text">计算</el-button>
				<el-form-item class="el_form_item" label="保障服务费" style="width:320px;margin-left: 10px">
					<el-input class="el_inner_width" v-model="form.cost_info.insure_total" @input="freight_count">
						<el-button slot="append">元</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="货物金额" style="width:320px">
					<el-input class="el_inner_width" v-model="form.cost_info.goods_total"  @input="insure_count(form.cost_info.goods_total)">
						<el-button slot="append">元</el-button>
					</el-input>
				</el-form-item>
			</div>
		</el-form>

		<div class="bottom_btns">
			<el-button type="primary" @click="sub()">提交</el-button>
		</div>

	</el-dialog>
</template>
<script>
	export default {
		props:{
			is_show:Number,
			tord_info:Object,
		},
		data() {
			return {
				manual_ver_checked:false, // 保费是否从司机运费扣除
				//页面是否显示
				is_show_in_page:false,

				//已支付和支付中的金额
				payed_and_paying:0,

				//剩余货主需付金额
				freight_total_surplus:0,

				//已结算金额
				settled_total:0,

				//剩余平台需结金额
				give_carrier_total_surplus:0,

				//支付记录条数
				payed_list_total:0,

				//新货主需付
				new_freight_total:0,
				new_freight_total1:0,

				//新司机需结
				new_give_carrier_total:0,

				//表单
				form:{
					cargo_name_type:'',//货物名称和类型
					out_trade_num:'',//上游单号
					cargo_weight:'',//货物重量和体积
					cargo_volume:'',//货物重量和体积
					case_addr_text:'',//装货地详细地址
					aim_addr_text:'',//卸货地详细地址
					truck_owner_driver_truck_info_text:'',//承运人信息和司机信息和货车信息
					cost_info:{},//费用信息
					distance:'',//距离
					give_driver_total:'',//给司机得金额
					give_truck_owner_total:'',//给车队长得金额
				},

				new_invoice_total:0,//开票金额
				new_invoice_total1:'',

				give_driver_total_1:'',//初始给司机得金额
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.is_show_in_page=true;
					this.set_buf_data(this.tord_info)
				}
			},
		},
		methods: {
			//保费是否从司机运费中扣除
			manual_ver_change(vuale){
				//从司机运费扣除
				if(vuale){
					//勾选 给司机金额 - 保费
					let total =  Number(this.give_driver_total_1)- Number(this.form.cost_info.insure_total)
					if(total <0){
						total = 0
					}
					this.form.give_driver_total =total
					//重新计算货主需付
					this.new_freight_total = Number(this.form.give_driver_total) + Number(this.form.cost_info.insure_total)+Number(this.form.give_truck_owner_total)
				}else{
					//不勾选 给司机金额 + 保费
					this.form.give_driver_total = Number(this.give_driver_total_1)+ Number(this.form.cost_info.insure_total)
					// 重新计算货主需付
					this.new_freight_total = Number(this.form.give_driver_total) +Number(this.form.give_truck_owner_total)
				}
			},
			//保险费修改
			freight_count(e){
				//货主需付也要变化
				let num =  Number(this.new_freight_total1)+Number(e)
				this.new_freight_total = num
				
			},
			//弹窗关闭
			close(){
				// this.form.cost_info.insure_total =0
				// this.form.cost_info.goods_total =0
				//已支付和支付中的金额
				this.payed_and_paying=0,

				//剩余货主需付金额
				this.freight_total_surplus=0,

				//已结算金额
				this.settled_total=0,

				//剩余平台需结金额
				this.give_carrier_total_surplus=0,

				//支付记录条数
				this.payed_list_total=0,

				//新货主需付
				this.new_freight_total=0,

				//新司机需结
				this.new_give_carrier_total=0,

				//表单
				this.form={
					cargo_name_type:'',//货物名称和类型
					out_trade_num:'',//上游单号
					cargo_weight:'',//货物重量和体积
					cargo_volume:'',//货物重量和体积
					case_addr_text:'',//装货地详细地址
					aim_addr_text:'',//卸货地详细地址
					truck_owner_driver_truck_info_text:'',//承运人信息和司机信息和货车信息
					cost_info:{},//费用信息
					distance:'',//距离
					give_driver_total:'',//给司机得金额
					give_truck_owner_total:'',//给车队长得金额
				},
				
				this.new_invoice_total=0,//开票金额
				this.new_invoice_total1=''
			},
			//计算
			invoice_count(){
				//开票点数
				let extra_service_charge_rate = Number(this.tord_info.act_set_info.extra_service_charge_rate)/100
				let num = 1 - extra_service_charge_rate
				//货主需付加保险费金额
				let num2 = Number(this.new_freight_total)+Number(this.form.cost_info.insure_total)
				//计算
				let total = Number(num2 /num).toFixed(2)
				this.new_invoice_total =total 
			},
			//修改货物金额自动计算保险费
			insure_count(goods_total){
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'get_insure_total',
						goods_total,
						shipper_num:this.tord_info.shipper_num
					},callback:(data)=>{
						this.form.cost_info.insure_total = data.insure_total
						// //计算货主需付
				        this.new_freight_total =Number(this.form.give_driver_total)+Number(this.form.give_truck_owner_total) +Number(this.form.cost_info.insure_total)
					}
				
				})
				// if(num ==0){
				// 	this.form.cost_info.insure_total = 0
				// 	return
				// }
				// let insure_num = Number(num *this.tord_info.shipper_company_info.insure_num).toFixed(2)
				// //小于5 强制等于5
				// if(insure_num<5){
				// 	this.form.cost_info.insure_total = 5
				// }else{
				// 	this.form.cost_info.insure_total = insure_num
				// }

				// //计算货主需付
				// this.new_freight_total =Number(this.form.give_driver_total)+Number(this.form.give_truck_owner_total) +Number(this.form.cost_info.insure_total)
			},
			give_driver_proportion(value){
				let num = 0
				if(value > Number(this.new_freight_total)){
					this.$my.other.msg({
						type:'info',
						str:'修改金额大于总运费',
					});
					num= this.new_freight_total - this.form.give_truck_owner_total-this.form.cost_info.insure_total
					this.form.give_driver_total = num.toFixed(2)
					return
				}
				num= this.new_freight_total-value -this.form.cost_info.insure_total
				this.form.give_truck_owner_total  = num.toFixed(2)
			},
			give_truck_owner_proportion(value){
				let num = 0
				if(value > Number(this.new_freight_total)){
					this.$my.other.msg({
						type:'info',
						str:'修改金额大于总运费',
					});
					num=this.new_freight_total-this.form.give_driver_total -this.form.cost_info.insure_total
					this.form.give_truck_owner_total = num.toFixed(2)
					return
				}
			
				num= this.new_freight_total-value-this.form.cost_info.insure_total
				this.form.give_driver_total  = num.toFixed(2)
			},
			focus_new_invoice_total(e){
				if(e == 0){
					this.new_invoice_total = this.new_invoice_total1
				}
			},
			//距离刷新
			distance_refresh(){
				var that = this
				var amapDriving = new AMap.Driving()
				var start_province = this.tord_info.case_prov + this.tord_info.case_city + this.tord_info.case_county
				var end_province = this.tord_info.aim_prov + this.tord_info.aim_city + this.tord_info.aim_county
				amapDriving.search(
					[{keyword: start_province, city: this.case_addr_text },{ keyword: end_province, city: this.aim_addr_text },],
					function (status, result) {
						if(status=='complete'&&result.info=='OK'){
							if(result.routes[0].distance == 0){
								that.$my.other.msg({
									type:'warning',
									str:'距离刷新错误,请手动填写',
								});
								return
							}
							that.form.distance=(result.routes[0].distance/1000).toFixed(3)
						}
					}
				)
			},
			//提交
			sub(){

				//货物重量必须是数字,而且必须大于0
				if(this.$my.check.is_num(this.form.cargo_weight) && this.form.cargo_weight<0){
					this.$my.other.msg({
						type:'warning',
						str:'货物重量必须是数字,而且必须大于0',
					});
					return;
				}

				//货物体积必须是数字,而且必须大于0
				if(this.$my.check.is_num(this.form.cargo_volume) && this.form.cargo_volume<0){
					this.$my.other.msg({
						type:'warning',
						str:'货物体积必须是数字,而且必须大于0',
					});
					return;
				}

				//新的货主需付金额不能低于已付金额
				if(this.new_freight_total<this.payed_and_paying){
					this.$my.other.msg({
						type:'warning',
						str:'新的货主需付金额不能低于已付金额',
					});
					return;
				}

				//新的平台需结金额不能低于已结金额
				if(this.new_give_carrier_total<this.settled_total){
					this.$my.other.msg({
						type:'warning',
						str:'新的平台需结金额不能低于已结金额',
					});
					return;
				}

				//如果是服务费率模式,货主需付金额和平台需结金额必须相等
				let freight_total1=parseFloat(this.form.cost_info.freight_total).toFixed(2);
				let freight_total2=parseFloat(this.form.cost_info.give_carrier_total).toFixed(2);
				if(this.form.cost_info.calc_mode==1 && freight_total1!=freight_total2){
					// this.$my.other.msg({
					// 	type:'warning',
					// 	str:'如果是服务费率模式,货主需付金额和平台需结金额必须相等',
					// });
					// return;
				}

				//询问
				this.$my.other.confirm({
					content:"点击'确定'修改运费",
					confirm:()=>{
						
						//调接口
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_cargo_weight_volume_edit_and_cost_edit_by_admin',
								truck_tord_num:this.tord_info.truck_tord_num,
								cargo_weight:this.form.cargo_weight,
								cargo_volume:this.form.cargo_volume,
								calc_mode:this.form.cost_info.calc_mode,
								insure_total:this.form.cost_info.insure_total,
								goods_total:this.form.cost_info.goods_total,
								freight_total:this.new_freight_total,
								give_carrier_total:this.give_carrier_total,
								distance:this.form.distance,
								new_invoice_total:this.new_invoice_total,
								give_truck_owner_total:this.form.give_truck_owner_total,
								give_driver_total:this.form.give_driver_total
							},
							callback:(data)=>{
								
								//报告结果
								this.$my.other.msg({
									type:'success',
									str:'操作成功',
								});

								//关闭
								this.is_show_in_page=false;

								//通知
								this.$emit("edited");
							}
						});
					}
				})
			},

			//切换结算方式
			calc_mode_change(){
				if(this.form.cost_info.calc_mode==1){
					this.new_give_carrier_total=this.new_freight_total
				}
			},

			//新货主需付变化
			freight_total_input(){
				if(this.form.cost_info.calc_mode==1){
					this.new_give_carrier_total=this.new_freight_total
				}
			},

			//置入缓存数据
			set_buf_data(tord_info){
				// this.new_invoice_total = tord_info.should_invoice_total
				this.new_invoice_total1 = tord_info.should_invoice_total
				//货物名称和类型
				this.form.cargo_name_type=tord_info.cargo_name+'/'+tord_info.cargo_type
				this.form.out_trade_num=tord_info.out_trade_num
				this.form.cargo_weight=tord_info.cargo_weight
				this.form.cargo_volume=tord_info.cargo_volume
				this.form.case_addr_text=tord_info.case_prov+'/'+tord_info.case_city+tord_info.case_county+'/'+tord_info.case_other_obj.addr_info
				this.form.aim_addr_text=tord_info.aim_prov+'/'+tord_info.aim_city+tord_info.aim_county+'/'+tord_info.aim_other_obj.addr_info
				this.form.truck_owner_driver_truck_info_text=	'(承运人:'+tord_info.truck_owner_info.name+'/'+tord_info.truck_owner_info.tel+')  '+
																'(司机:'+tord_info.driver_info.name+'/'+tord_info.driver_info.tel+')  '+
																'(货车:'+tord_info.truck_info.truck_plate_num+'/'+tord_info.truck_info.truck_type_name+'/'+tord_info.truck_info.truck_type_name2+')'
				this.form.cost_info=tord_info.cost_info
				this.form.distance = tord_info.distance
				//读取支付记录
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_payed_list_by_admin',
						truck_tord_num:tord_info.truck_tord_num,
						is_get_truck_owner_info:false,
						is_get_driver_info:false,
						is_get_tord_info:false,
						num:100000,
					},
					callback:(data)=>{

						//已支付和支付中的金额
						let payed_and_paying=0

						//已结算金额
						let settled_total=0

						for(var payed_item of data.list){
							//已支付和支付中的金额
							if(payed_item.pay_status!=4){
								if(payed_item.calc_mode==1){
									if(payed_item.this_settled>0){
										payed_and_paying+=parseFloat(payed_item.this_settled);
									}
									
								}else if(payed_item.calc_mode==2){
									if(payed_item.this_payed>0){
										payed_and_paying+=parseFloat(payed_item.this_payed);
									}
									// payed_and_paying+=parseFloat(payed_item.this_payed);
								}
							}
							if(payed_item.this_settled>0){
								//已结算金额
							    settled_total+=parseFloat(payed_item.this_settled);
							}
							
						}

						//已支付和支付中的金额
						this.payed_and_paying=payed_and_paying

						//剩余货主需付金额
						this.freight_total_surplus=(tord_info.cost_info.freight_total-this.payed_and_paying).toFixed(2);
						//已结算金额
						this.settled_total=settled_total;
						//剩余平台需结金额
						this.give_carrier_total_surplus=(tord_info.cost_info.give_carrier_total-this.settled_total).toFixed(2);

						//支付记录条数
						this.payed_list_total=data.list.length

						//新货主需付
						this.new_freight_total=tord_info.cost_info.freight_total
						this.new_freight_total1=tord_info.cost_info.freight_total
						//新司机需结
						this.new_give_carrier_total=tord_info.cost_info.give_carrier_total

						this.form.give_driver_total = tord_info.cost_info.give_driver_total
						this.give_driver_total_1 = tord_info.cost_info.give_driver_total
						this.form.give_truck_owner_total = tord_info.cost_info.give_truck_owner_total
					
					}
				})
			},
		}
	}
</script>
<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
		.img_btn{
			border: 1px solid #bbb;
			border-radius: 5px;
			cursor: pointer;
			padding: 5px;
			height:300px;
			.btn{
				text-align: center;
				line-height: 290px;
				font-size: 14px;
			}

			.img{
				display: block;
				margin:auto;
				max-width: 100%;
				max-height: 290px
			}
		}
		.bottom_text{
			margin-top: 5px;
			text-align: center;
		}
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}
	
	.bottom_btns{
		text-align: center;
		margin-top: 30px;
	}

	.form_ordinary{
		// height: calc(100% - 91px);
		padding: 10px 0;
		overflow-y:auto;
	}
	
</style>