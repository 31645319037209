/* 基于高德地图的轨迹校验 */
const check = {
	init(app){

		this.$my=app.$my

		//加载地图
		app.$amap.load({
			plugins:[
				'AMap.DistrictSearch',
			]
		}).then((AMap)=>{
			this.AMap=AMap
			this.district=new AMap.DistrictSearch({
				subdistrict:0,  	//获取边界不需要返回下级行政区
				extensions:'all',  	//返回行政区边界坐标组等具体信息
				level:'district'  	//查询行政级别为 区/县
			});
		})
	},
	check(input){
		if(!input.case_code){
			if(input.callback)input.callback({
				code:1,
				error_info:'缺少装货地区划'
			})
			return
		}
		if(!input.aim_code){
			if(input.callback)input.callback({
				code:1,
				error_info:'缺少卸货地区划'
			})
			return
		}
		if(!this.$my.check.is_num(input.start_time)){
			if(input.callback)input.callback({
				code:1,
				error_info:'缺少发车时间'
			})
			return
		}
		if(!this.$my.check.is_num(input.arrived_time)){
			if(input.callback)input.callback({
				code:1,
				error_info:'缺少到达时间'
			})
			return
		}
		if(!this.$my.check.is_arr(input.path)){
			if(input.callback)input.callback({
				code:1,
				error_info:'缺少轨迹'
			})
			return
		}

		//获取发车后15小时内的轨迹
		var path_of_start_check=[]
		var path_of_arrived_check=[]
		for(var path_item of input.path){//取发车后15小时和到达前15小时内的轨迹作为对应校验的数据

			if(path_item.timestamp-input.start_time<=54000){//轨迹点的时间距离发车时间低于15小时
				path_of_start_check.push([
					path_item.lon,
					path_item.lat,
				]);
			}

			if(input.arrived_time-path_item.timestamp<=54000){//轨迹点的时间距离到达时间低于15小时
				path_of_arrived_check.push([
					path_item.lon,
					path_item.lat,
				]);
			}
		}

		//奇怪的事情发生了...
		if(path_of_start_check.length==0){
			if(input.callback)input.callback({
				code:1,
				error_info:'发车时间点后15个小时内无轨迹'
			})
			return
		}
		if(path_of_start_check.length==0){
			if(input.callback)input.callback({
				code:1,
				error_info:'到货时间点前15个小时内无轨迹'
			})
			return
		}

		//结果集
		let rel_list={};
		let is_end=function(){
			if(!rel_list.case_check_rel){
				return;
			}
			if(!rel_list.aim_check_rel){
				return;
			}
			if(rel_list.case_check_rel.code==0 && rel_list.aim_check_rel.code==0){
				if(input.callback)input.callback({
					code:0,
					error_info:''
				})
			}else if(rel_list.case_check_rel.code==1){
				if(input.callback)input.callback({
					code:1,
					error_info:rel_list.case_check_rel.error_info
				})
			}else if(rel_list.aim_check_rel.code==1){
				if(input.callback)input.callback({
					code:1,
					error_info:rel_list.aim_check_rel.error_info
				})
			}
		};

		//获取装货地边界
		this.district.search(input.case_code,(status,result)=>{

			if(status!=='complete'){
				rel_list.case_check_rel={
					code:1,
					error_info:'区划搜索失败'
				};
				is_end();
				return;
			}

			//生成行政区边线
			if (result.districtList[0].boundaries){
				for(let boundaries_index=0;boundaries_index<result.districtList[0].boundaries.length;boundaries_index++){
					let boundaries_item=result.districtList[0].boundaries[boundaries_index]
					let case_area_item=[];
					for(let LngLat of boundaries_item){
						case_area_item.push([
							LngLat.lng,
							LngLat.lat,
						]);
					}

					//发货地校验
					for(let point_index=0;point_index<path_of_start_check.length;point_index++){
						let point_item=path_of_start_check[point_index]
						let isPointInRing=this.AMap.GeometryUtil.isPointInRing(point_item,case_area_item);
						if(isPointInRing){
							rel_list.case_check_rel={
								code:0,
								error_info:''
							};
							is_end();
							return;
						}else{
							if(point_index==path_of_start_check.length-1 && boundaries_index==result.districtList[0].boundaries.length-1){
								rel_list.case_check_rel={
									code:1,
									error_info:'发货地与运行轨迹匹配失败'
								};
								is_end();
								return
							}
						}
					}
				}
			}
		});

		//获卸货货地边界
		this.district.search(input.aim_code,(status,result)=>{

			if(status!=='complete'){
				rel_list.aim_check_rel={
					code:1,
					error_info:'区划搜索失败'
				};
				is_end();
				return;
			}

			//生成行政区边线
			if (result.districtList[0].boundaries){
				for(let boundaries_index=0;boundaries_index<result.districtList[0].boundaries.length;boundaries_index++){
					let boundaries_item=result.districtList[0].boundaries[boundaries_index]
					let aim_area_item=[];
					for(let LngLat of boundaries_item){
						aim_area_item.push([
							LngLat.lng,
							LngLat.lat,
						]);
					}

					//卸货地校验
					for(let point_index=0;point_index<path_of_arrived_check.length;point_index++){
						let point_item=path_of_arrived_check[point_index]
						let isPointInRing=this.AMap.GeometryUtil.isPointInRing(point_item,aim_area_item);
						if(isPointInRing){
							rel_list.aim_check_rel={
								code:0,
								error_info:''
							};
							is_end();
							return;
						}else{
							if(point_index==path_of_arrived_check.length-1 && boundaries_index==result.districtList[0].boundaries.length-1){
								rel_list.aim_check_rel={
									code:1,
									error_info:'卸货地与运行轨迹匹配失败'
								};
								is_end();
								return
							}
						}
					}
				}
			}
		});
	}
}

export default check